import React, { FC, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useRouter } from 'next/router'
import { HistoryProtoResponseId } from '@open-api/ump/response-history'
import { PersonalOffersProtoComponentProps } from '@open-api/ump/st-personal-offers'

import { FAOffers } from '@components/FullApproveWidget/types'
import { ComponentType } from '@reducers/fullApproveWidget/types'

import { responseHistorySave } from '@reducers/fullApproveWidget/fullApproveWidget'
import { mobilePhoneSelector } from '@selectors/auth'
import { MAIN_PATH } from '@root/constants'
import { mainPageOfferGtm } from '@root/utils/gtm/mainPage/events'
import { DataLayerEvents } from '@root/utils/gtm/mainPage/types'
import { useGoTo } from '@utils/hooks'
import { FantomProductItem } from './FantomProductItem'

interface Props extends Omit<FAOffers, 'isLoading'> {
  isNonePaddingBottom?: boolean
}

export const FantomProduct: FC<Props> = ({ offers, componentType, isNonePaddingBottom }) => {
  const dispatch = useDispatch()
  const phoneNum = useSelector(mobilePhoneSelector)
  const { getGoTo } = useGoTo()
  const router = useRouter()

  const handleClick = (event: React.MouseEvent<HTMLDivElement>, offer: PersonalOffersProtoComponentProps) => {
    const link = `${MAIN_PATH}${offer.urlForAction}`

    mainPageOfferGtm(DataLayerEvents.FANTOM_PRODUCT_CLICK, offer)
    try {
      getGoTo(link)()
    } catch (_err) {
      router.push(link)
    }
  }

  const handleCloseClick = useCallback(
    (event: React.MouseEvent<HTMLDivElement>, offer: PersonalOffersProtoComponentProps) => {
      event.stopPropagation()

      dispatch(
        responseHistorySave({
          offer,
          responseId:
            componentType === ComponentType.FANTOM_LOAN
              ? HistoryProtoResponseId.Id10512
              : HistoryProtoResponseId.Id10412,
          componentType,
          phoneNum,
        })
      )
      mainPageOfferGtm(DataLayerEvents.FANTOM_PRODUCT_CLOSE_CLICK, offer)
    },
    [componentType, dispatch, phoneNum]
  )

  if (!offers) {
    return null
  }

  return (
    <>
      {offers.map((offer) => (
        <FantomProductItem
          key={offer.offerId}
          offer={offer}
          isNonePaddingBottom={isNonePaddingBottom}
          onClick={(event: React.MouseEvent<HTMLDivElement>) => handleClick(event, offer)}
          onClose={(event: React.MouseEvent<HTMLDivElement>) => handleCloseClick(event, offer)}
        />
      ))}
    </>
  )
}
